<template>
  <div class="edp-backend">
    <edp-backend-user />

    <section class="edp-backend-middle">
      <edp-backend-menu />

      <!-- content -->
      <section class="edp-backend-content">
        <div class="edp-backend-content-out">
          <mainTitle> </mainTitle>
          <section class="edp-backend-conditionSearch">
            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendEntry.atableTitle1") }}
              </div>
              <div class="edp-backend-conditionSearch-content">
                <el-input
                  max="100"
                  :placeholder="$t('edpBackendCommon.placeholder')"
                  v-model="query.fieldCommentCnName"
                  clearable
                />
              </div>
            </div>
            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendEntry.atableTitle2") }}
              </div>
              <div class="edp-backend-conditionSearch-content">
                <el-input
                  max="100"
                  :placeholder="$t('edpBackendCommon.placeholder')"
                  v-model="query.fieldCommentEnName"
                  clearable
                />
              </div>
            </div>
            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendEntry.tableTite5") }}
              </div>

              <div class="edp-backend-conditionSearch-content">
                <el-select
                  v-model="query.isEnabled"
                  clearable
                  :placeholder="$t('edpBackendCommon.select')"
                >
                  <el-option
                    :label="$t('edpBackendCommon.statusNp')"
                    :value="false"
                  ></el-option>
                  <el-option
                    :label="$t('edpBackendCommon.statusYes')"
                    :value="true"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div
              class="edp-backend-conditionSearch-square conditionSearch-square-last"
            >
              <el-button size="small" @click="onclear">{{
                $t("edpBackendCommon.clear")
              }}</el-button>
              <el-button size="small" type="primary" @click="getList(1)">{{
                $t("edpBackendCommon.search")
              }}</el-button>
            </div>
          </section>
          <section class="edp-backend-common-table">
            <div class="table-headControlLine">
              <div>
                <el-button type="primary" size="small" @click="onShow(1)">
                  {{ $t("edpBackendCommon.add") }}</el-button
                >
              </div>
            </div>

            <el-table
              stripe
              :data="pageList"
              style="width: 100%"
              v-loading="loading"
            >
              <el-table-column fixed prop="id" label="ID" width="50">
              </el-table-column>

              <el-table-column
                prop="fieldCommentCnName"
                :label="$t('edpBackendEntry.atableTitle1')"
              >
              </el-table-column>

              <el-table-column
                prop="fieldCommentEnName"
                :label="$t('edpBackendEntry.atableTitle2')"
              >
              </el-table-column>

              <el-table-column
                prop="isEnabled"
                :label="$t('edpBackendEntry.tableTite5')"
              >
                <template slot-scope="scope">
                  <el-tag
                    :type="scope.row.isEnabled ? 'success' : 'danger'"
                    disable-transitions
                    >{{
                      scope.row.isEnabled
                        ? $t("edpBackendCommon.statusYes")
                        : $t("edpBackendCommon.statusNp")
                    }}</el-tag
                  >
                </template>
              </el-table-column>

              <el-table-column
                prop="modifyTime"
                :label="$t('edpBackendEntry.dtableTitle2')"
              ></el-table-column>

              <el-table-column
                :label="$t('edpBackendCommon.operate')"
                fixed="right"
                width="110"
              >
                <template slot-scope="scope">
                  <el-button type="text" @click="onShow(2, scope.row)">
                    {{ $t("edpBackendCommon.edit") }}</el-button
                  >
                </template>
              </el-table-column>
            </el-table>

            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="pageTotal"
            >
            </el-pagination>
          </section>
        </div>
      </section>
      <!-- content end -->
    </section>
    <el-dialog
      :title="dialogTitle"
      width="52%"
      :visible.sync="editShow"
      :before-close="onClose"
    >
      <el-form :model="form" label-width="145px" ref="funForm">
        <el-form-item label="ID">
          <el-input
            v-model="form.id"
            autocomplete="off"
            :placeholder="$t('edpBackendCommon.placeholder')"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('edpBackendEntry.atableTitle1')"
          prop="fieldCommentCnName"
          :rules="[
            { required: true, message: $t('edpBackendCommon.notEmpty') },
          ]"
        >
          <el-input
            v-model="form.fieldCommentCnName"
            :maxlength="50"
            autocomplete="off"
            :placeholder="$t('edpBackendCommon.placeholder')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('edpBackendEntry.atableTitle2')"
          prop="fieldCommentEnName"
          :rules="[
            { required: true, message: $t('edpBackendCommon.notEmpty') },
          ]"
        >
          <el-input
            v-model="form.fieldCommentEnName"
            :maxlength="100"
            autocomplete="off"
            :placeholder="$t('edpBackendCommon.placeholder')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('edpBackendEntry.atableTitle3')"
          prop="catId"
          :rules="[
            { required: true, message: $t('edpBackendCommon.notEmpty') },
          ]"
        >
          <el-cascader
            :placeholder="$t('edpBackendCommon.select')"
            v-model="form.catId"
            :options="menuOptions"
            :props="{ checkStrictly: true, value: 'id' }"
            clearable
            filterable
          ></el-cascader>
        </el-form-item>
        <el-form-item
          :label="$t('edpBackendEntry.atableTitle4')"
          prop="fieldTableName"
          :rules="[
            { required: true, message: $t('edpBackendCommon.notEmpty') },
          ]"
        >
          <el-row>
            <el-col :span="12"
              ><el-select
                v-model="form.fieldTableName"
                :placeholder="
                  $t('edpBackendCommon.placeholder') +
                  $t('edpBackendEntry.atableTitle8')
                "
                clearable
              >
                <el-option
                  v-for="(item, i) in tables"
                  :key="i"
                  :label="item.tableComment"
                  :value="item.tableName"
                ></el-option> </el-select
            ></el-col>
            <el-col :span="12"
              ><el-select
                v-model="form.fieldName"
                :placeholder="
                  $t('edpBackendCommon.placeholder') +
                  $t('edpBackendEntry.atableTitle9')
                "
                clearable
              >
                <el-option
                  v-for="(item, i) in fields"
                  :key="i"
                  :label="item.columnComment"
                  :value="item.columnName"
                ></el-option> </el-select
            ></el-col>
          </el-row>
        </el-form-item>
        <el-form-item
          :label="$t('edpBackendEntry.atableTitle5')"
          prop="fieldType"
          :rules="[
            { required: true, message: $t('edpBackendCommon.notEmpty') },
          ]"
        >
          <el-select
            v-model="form.fieldType"
            :placeholder="$t('edpBackendCommon.select')"
            clearable
          >
            <el-option
              v-for="(item, i) in fieldTypes"
              :key="i"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('edpBackendEntry.atableTitle6')"
          prop="fieldValueMatchMode"
          :rules="[
            { required: true, message: $t('edpBackendCommon.notEmpty') },
          ]"
        >
          <el-select
            v-model="form.fieldValueMatchMode"
            :placeholder="$t('edpBackendCommon.select')"
            clearable
          >
            <el-option
              v-for="(item, i) in Modes"
              :key="i"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('edpBackendEntry.atableTitle7')"
          prop="fieldValueType"
          :rules="[
            { required: true, message: $t('edpBackendCommon.notEmpty') },
          ]"
        >
          <el-select
            v-model="form.fieldValueType"
            :placeholder="$t('edpBackendCommon.select')"
            clearable
          >
            <el-option
              v-for="(item, i) in valueTypes"
              :key="i"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.fieldValueType == 'remote'">
          <el-input
            v-model="form.fieldValueOptionUri"
            autocomplete="off"
            :placeholder="$t('edpBackendCommon.placeholder')"
          ></el-input>
        </el-form-item>
        <template v-if="form.fieldValueType == 'local'">
          <el-form-item v-for="(item, i) in form.fieldValueOption" :key="i">
            <el-row :gutter="10">
              <el-col :span="7"
                ><el-input
                  v-model="item.value"
                  autocomplete="off"
                  :placeholder="
                    $t('edpBackendCommon.placeholder') +
                    $t('edpBackendEntry.mtableTitle6')
                  "
                ></el-input
              ></el-col>
              <el-col :span="7"
                ><el-input
                  v-model="item.labelCnName"
                  autocomplete="off"
                  :placeholder="
                    $t('edpBackendCommon.placeholder') +
                    $t('edpBackendEntry.formTit1')
                  "
                ></el-input
              ></el-col>
              <el-col :span="7"
                ><el-input
                  v-model="item.labelEnName"
                  autocomplete="off"
                  :placeholder="
                    $t('edpBackendCommon.placeholder') +
                    $t('edpBackendEntry.tableTite2')
                  "
                ></el-input
              ></el-col>
              <el-col :span="3">
                <i
                  v-if="i != 0"
                  class="el-icon-minus"
                  style="font-size: 18px; margin-right: 8px"
                  @click.prevent="removeDomain(item)"
                ></i>
                <i
                  class="el-icon-plus"
                  style="font-size: 18px"
                  @click="addDomain"
                  v-if="i == form.fieldValueOption.length - 1"
                ></i
              ></el-col>
            </el-row>
          </el-form-item>
        </template>
        <el-form-item :label="$t('edpBackendEntry.dtableTitle8')">
          <el-switch
            v-model="form.isEnabled"
            active-color="rgba(97, 191, 180, 1)"
            inactive-color="rgba(0, 0, 0, 0.24705882352941178)"
          >
          </el-switch>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="onClosed">{{
          $t("edpBackendCommon.canale")
        }}</el-button>
        <el-button type="primary" @click="onSave('funForm')">{{
          $t("edpBackendCommon.save")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  name: "backendFunction",
  data() {
    return {
      query: {
        fieldCommentCnName: "",
        fieldCommentEnName: "",
        isEnabled: "",
      },
      menuOptions: [],
      pageList: [],
      currentPage: 1,
      pageSize: 10,
      pageTotal: 0,
      loading: false,
      editShow: false,
      dialogTitle: this.$t("edpBackendCommon.add"),
      form: {
        id: null,
        fieldCommentCnName: null,
        fieldCommentEnName: null,
        fieldName: null,
        fieldTableName: "",
        fieldTableAlias: null,
        fieldType: null,
        fieldValueType: null,
        fieldValueOption: [],
        fieldValueOptionUri: null,
        fieldValueMatchMode: null,
        catId: 0,
        catCnName: null,
        catEnName: null,
        isEnabled: true,
      },
      tables: [],
      fields: [],
      methods: [
        { name: "模板一", value: "1" },
        { name: "模板二", value: "2" },
      ],
      fieldTypes: [
        { name: "数字型", value: "number" },
        { name: "数组型", value: "array" },
        { name: "日期", value: "date" },
      ],
      Modes: [
        { name: "like", value: "like" },
        { name: "in", value: "in" },
        { name: "equal", value: "equal" },
      ],
      valueTypes: [
        { name: "自定义", value: "custom" },
        { name: "本地", value: "local" },
        { name: "远程", value: "remote" },
      ],
    };
  },
  watch: {
    "form.fieldTableName"(neval, oldval) {
      if (neval.length && neval != oldval && oldval.length) {
        this.form.fieldName = null;
      }
      this.getFields(neval);
    },
    "form.fieldValueType"(val) {
      if (val === "local" && !this.form.fieldValueOption.length) {
        this.form.fieldValueOption = [
          {
            labelCnName: "",
            labelEnName: "",
            value: "",
          },
        ];
      }
    },
  },
  mounted() {
    this.getList();
    this.pageInit();
  },
  methods: {
    ...mapActions({
      fieldList: "user/fieldList",
      addField: "user/addField",
      editField: "user/editField",
      categoryList: "user/categoryList",
      getTable: "user/getTable",
      getColumn: "user/getColumn",
    }),
    removeDomain(item) {
      var index = this.form.fieldValueOption.indexOf(item);
      if (index !== -1) {
        this.form.fieldValueOption.splice(index, 1);
      }
    },
    addDomain() {
      this.form.fieldValueOption.push({
        labelCnName: "",
        labelEnName: "",
        value: "",
      });
    },
    async pageInit(num) {
      let _this = this;
      this.loading = true;

      if (num) {
        this.currentPage = num;
      }

      let params = {
        current: this.currentPage,
        size: this.pageSize,
      };

      let res = await this.categoryList(params);

      let pageList = res.data;

      this.menuOptions = JSON.parse(JSON.stringify(pageList));

      function menuListInit(data) {
        let pageLs = data;
        pageLs.forEach((val, e) => {
          _this.$set(val, "value", val.id);
          _this.$i18n.locale === "cn"
            ? _this.$set(val, "label", val.catCnName)
            : _this.$set(val, "label", val.catEnName);

          if (val.children && val.children.length > 0) {
            menuListInit(val.children);
          }
        });
      }

      menuListInit(this.menuOptions);
      console.log(this.menuOptions);
      this.loading = false;
    },
    async getList(val) {
      if (val) {
        this.currentPage = val;
      }
      let params = {
        current: this.currentPage,
        size: this.pageSize,
        ...this.query,
      };
      let res = await this.fieldList(params);
      console.log(res);
      this.pageList = res.data.records;
      this.currentPage = res.data.current;
      this.pageTotal = res.data.total;
    },
    async getTables() {
      let res = await this.getTable();
      this.tables = res.data;
    },
    async getFields(val) {
      let params = {
        tableName: val,
      };
      let res = await this.getColumn(params);
      this.fields = res.data;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    onclear() {
      this.query = {
        fieldCommentCnName: "",
        fieldCommentEnName: "",
        isEnabled: "",
      };
    },
    onClose(done) {
      for (var key in this.form) {
        if (key === "isDefault" || key === "isEnabled") {
          this.form[key] = false;
        } else {
          this.form[key] = "";
        }
      }
      done();
    },
    onShow(val, data) {
      if (val == 1) {
        this.dialogTitle = this.$t("edpBackendCommon.add");
      } else {
        this.dialogTitle = this.$t("edpBackendCommon.edit");
        this.form = JSON.parse(JSON.stringify(data));
        // for (var key in this.form) {
        //   this.form[key] = data[key];
        // }
        console.log(this.form);
      }
      this.getTables();
      const that = this;
      this.$nextTick(() => {
        that.$refs.funForm.clearValidate();
      });
      this.editShow = true;
    },
    onClosed() {
      this.editShow = false;
      for (var key in this.form) {
        if (key === "isEnabled") {
          this.form[key] = false;
        } else if (key === "fieldValueOption") {
          this.form[key] = [];
        } else {
          this.form[key] = "";
        }
      }
    },
    async onSave(formName) {
      let res = {};
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let formParams = JSON.parse(JSON.stringify(this.form));
          if (this.form.catId && this.form.catId.length > 0) {
            formParams.catId = this.form.catId[this.form.catId.length - 1];
          } else if (this.form.catId && this.form.catId.length === undefined) {
            formParams.catId = this.form.catId;
          } else {
            formParams.catId = "";
          }

          if (this.form.id) {
            res = await this.editField(formParams);
          } else {
            res = await this.addField(formParams);
          }

          if (res.success) {
            this.onClosed();
            this.getList();
            this.$message({
              type: "success",
              message: this.$t("edpBackendCommon.actionSuccess"),
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";
</style>
