import { render, staticRenderFns } from "./backend-advquery.vue?vue&type=template&id=46a5faba&"
import script from "./backend-advquery.vue?vue&type=script&lang=js&"
export * from "./backend-advquery.vue?vue&type=script&lang=js&"
import style0 from "./backend-advquery.vue?vue&type=style&index=0&id=46a5faba&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports